<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {computed, onMounted, ref, watch} from "vue";
import axios from "axios";
import {useHead} from "@vueuse/head";
import {useRoute, useRouter} from "vue-router";

const route = useRoute()
const router = useRouter()
const projectList = ref(null)
const current = ref(1)

const lastPage = computed(() => {
  return Math.ceil(projectList.value.count / 12)
})

const prevPage = computed(() => {
 if (current.value > 1) {
    return current.value - 1;
  } else {
    return 1
  }
})

const nextPage = computed(() => {
  if (current.value < lastPage.value) {
    return current.value + 1
  } else {
    return current.value
  }
})

const pages = computed(() => {
  const pages = []
  for (let i = 1; i <= lastPage.value; i++) {
    if (i === current.value || i === lastPage.value || (i >= current.value - 1 && i <= current.value + 1)) {
      pages.push(i);
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  return pages
})

function getToPage(page) {
  current.value = page;
  const query = {...route.query};
  query.page = page
  router.push({
    query
  })
}

function get_project_list() {
  let url = '/api/projects/'
  const page = Number(route.query.page)
  const params = new URLSearchParams()
  if (page) {
    params.append('page', page)
  }
  url = url + '?' + params.toString()
  axios
      .get(url)
      .then(response => {
        projectList.value = response.data
      })
      .catch(error => {
        console.log(error)
      });
}

onMounted(() => {
  get_project_list()
})

watch(() => route.query,
    () => {
      get_project_list()
    },
    {immediate: true})
useHead({
  title: 'TPA Housing - 100000m2 Production Plant, 20 Years Experienced Engineer, 30 Countries House Projects',
  meta: [
    {
      name: 'description',
      content: 'content="We can provide prefabricated design expertise, construction knowledge you need to build the house you want in short time and within your budget,Steel Structure House Building,Warehouse Steel Structure,Lightweight Steel Structure and other products and services'
    }
  ]
})
</script>

<template>
  <Header/>
  <!-- Page Banner Start -->
  <section class="page-banner-area overlay py-250 rpy-120 rel z-1 bgs-cover text-center"
           style="background-image: url(/static/image/Product.jpg);">
    <div class="container">
      <div class="banner-inner pt-70 rpt-60 text-white">
        <h1 class="page-title" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">Project Masonry</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
              data-aos-offset="50">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Project</li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  <!-- Page Banner End -->


  <!-- Project Masonry Area Start -->
  <section class="blog-standard-area py-130 rpy-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="blog-standard-wrap">
            <div v-if="projectList && projectList.results" v-for="list in projectList.results"
                 class="blog-standard-item"
                 data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
              <div class="image">
                <img :src="list.image_1" alt="Blog">
              </div>
              <div class="content">
                <h3><a :href="'/projects/' + list.site">{{ list.project_title }}</a></h3>
                <p>{{ list.overview }}</p>
                <a :href="'/projects/' + list.site" class="theme-btn style-two">Read More <i
                    class="far fa-arrow-right"></i></a>
              </div>
            </div>
            <ul class="pagination mt-25 flex-wrap">
              <li class="page-item disabled">
                <a @click="getToPage(prevPage)" class="page-link" href="#"><i class="fas fa-angle-left"></i></a>
              </li>
              <li v-if="projectList && projectList.results" v-for="page in pages" :key="page" class="page-item">
                <a v-if="page === current.value" class="page-link" href="#" @click="getToPage(page)">
                    {{ page }}
                  <span class="sr-only">(current)</span>
                </a>
                <a v-else-if="page === '...'" class="page-link" href="#">
                    {{ page }}
                </a>
                <a v-else class="page-link" @click="getToPage(page)" href="#">
                    {{ page }}
                <span class="sr-only">(current)</span>
                </a>
              </li>
              <!--              <li class="page-item"><a class="page-link" href="#">2</a></li>-->
              <!--              <li class="page-item"><a class="page-link" href="#">3</a></li>-->
              <!--              <li class="page-item"><a class="page-link" href="#">4</a></li>-->
              <li class="page-item">
                <a @click="getToPage(nextPage)" class="page-link" href="#"><i class="fas fa-angle-right"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Project Masonry Area End -->
  <Footer/>
</template>

<style scoped lang="scss">

</style>