<script setup>

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {computed, onMounted, ref, watch} from "vue";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import {useHead} from "@vueuse/head";

const route = useRoute()
const router = useRouter()
const blogList = ref(null);
const Keywords = ref()
const current = ref(1)

function get_blog_list() {
  let url = '/api/blogs'
  const title = route.query.blog_title
  const page = Number(route.query.page)
  const category = route.query.category
  const params = new URLSearchParams()
  if (title) {
    params.append('blog_title', title)
  }
  if (page) {
    params.append('page', page)
  }
  if (category) {
    params.append('category', category)
  }
  url = url + '?' + params.toString();
  axios
      .get(url)
      .then(response => {
        blogList.value = response.data
      })
      .catch(error => {
        console.log(error)
      });
}

const lastPage = computed(() => {
  return Math.ceil(blogList.value.count / 12)
})

const prevPage = computed(() => {
  if (current.value > 1) {
    return current.value - 1;
  } else {
    return 1
  }
})

const nextPage = computed(() => {
  if (current.value < lastPage.value) {
    return current.value + 1
  } else {
    return current.value
  }
})

const pages = computed(() => {
  const pages = []
  for (let i = 1; i <= lastPage.value; i++) {
    if (i === current.value || i === lastPage.value || (i >= current.value - 1 && i <= current.value + 1)) {
      pages.push(i);
    } else if (pages[pages.length - 1] !== '...') {
      pages.push('...')
    }
  }
  return pages
})

function searchBlog() {
  const search = Keywords.value.trim()
  router.push({
    query: {blog_title: search}
  })
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
}

function getPageFromUrl() {
  const page = route.query.page
  return page ? page : 1
}

function getToPage(page) {
  current.value = page
  const query = {...route.query}
  query.page = page
  router.push({
    query
  })
}

onMounted(() => {
  current.value = getPageFromUrl()
  get_blog_list()
})

watch(() => route.query,
    () => {
      get_blog_list()
    },
    {immediate: true})

useHead({
  title: 'TPA Housing - 100000m2 Production Plant, 20 Years Experienced Engineer, 30 Countries House Projects',
  meta: [
    {
      name: 'description',
      content: 'content="We can provide prefabricated design expertise, construction knowledge you need to build the house you want in short time and within your budget,Steel Structure House Building,Warehouse Steel Structure,Lightweight Steel Structure and other products and services'
    }
  ]
})
</script>

<template>
  <Header/>
  <!-- Page Banner Start -->
  <section class="page-banner-area overlay py-250 rpy-120 rel z-1 bgs-cover text-center"
           style="background-image: url(/static/image/Product.jpg);">
    <div class="container">
      <div class="banner-inner pt-70 rpt-60 text-white">
        <h1 class="page-title" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">Blog Standard</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-center" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
              data-aos-offset="50">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Blog</li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  <!-- Page Banner End -->


  <!-- Blog Standard Area Start -->
  <section class="blog-standard-area py-130 rpy-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="blog-standard-wrap">
            <div v-if="blogList && blogList.results" v-for="list in blogList.results" class="blog-standard-item"
                 data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
              <div class="image">
                <img :src="list.image_1" alt="Blog">
              </div>
              <div class="content">
                <ul class="blog-meta">
                  <li><i class="far fa-calendar-alt"></i> <a href="#">{{ formatDate(list.updated_at) }}</a></li>
                </ul>
                <h3><a :href="'/blogs/' + list.site">{{ list.blog_title }}</a></h3>
                <p>{{ list.overview }}</p>
                <a :href="'/blogs/' + list.site" class="theme-btn style-two">Read More <i class="far fa-arrow-right"></i></a>
              </div>
            </div>
            <ul class="pagination mt-25 flex-wrap">
              <li class="page-item disabled">
                <a @click="getToPage(prevPage)" class="page-link" href="#"><i class="fas fa-angle-left"></i></a>
              </li>
              <li v-if="blogList && blogList.results" v-for="page in pages" :key="page" class="page-item">
                <a v-if="page === current.value" class="page-link" href="#" @click="getToPage(page)">
                    {{ page }}
                  <span class="sr-only">(current)</span>
                </a>
                <a v-else-if="page === '...'" class="page-link" href="#">
                    {{ page }}
                </a>
                <a v-else class="page-link" @click="getToPage(page)" href="#">
                    {{ page }}
                <span class="sr-only">(current)</span>
                </a>
              </li>
              <!--              <li class="page-item"><a class="page-link" href="#">2</a></li>-->
              <!--              <li class="page-item"><a class="page-link" href="#">3</a></li>-->
              <!--              <li class="page-item"><a class="page-link" href="#">4</a></li>-->
              <li class="page-item">
                <a @click="getToPage(nextPage)" class="page-link" href="#"><i class="fas fa-angle-right"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="main-sidebar rmt-75">
            <div class="widget widget-search" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
                 data-aos-offset="50">
              <h5 class="widget-title">Search</h5>
              <form action="#" class="default-search-form">
                <input v-model="Keywords" type="text" placeholder="Keywords" required="">
                <button v-on:click.prevent="searchBlog" type="submit" class="searchbutton far fa-search"></button>
              </form>
            </div>
            <div class="widget widget-category" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500"
                 data-aos-offset="50">
              <h5 class="widget-title">Category</h5>
              <ul>
                <li><a :href="'?category=Industry'">Industry <i class="far fa-arrow-right"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog Standard Area End -->
  <Footer/>
</template>

<style scoped lang="scss">

</style>